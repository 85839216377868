/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbOneLineTextareaExternal
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Text area for entering data in four lines of 35 chars each. The purpose of the field is how the data is stored in database.
 *
 * @param {string=} ngModel The model for entered text.
 * @param {object=} ebForm Parent form model.
 * @param {expression=} ebDisabled Sets the disabled attribute on the element if the expression evaluates to truthy.
 * @param {siring} placeholder A text to be presented inside the field when no user data is entered.
 * @param {string} ebName Name of the input field.
 * @param {number=} ebRows Number lines of the text area.
 * @param {number} ebMaxlength Maximum langth of entered text.
 * @param {expression=} ebRequired If `true` the account number will be required.
 * @param {expression=} ebResizable If `true` the number of rows will be inferred from entered text.
 */

angular.module( 'halyk-authentication').directive('cbOneLineTextareaExternal', function($filter, pathService, $timeout,$interval, translate) {
    return {
        restrict: 'EA',
        replace: true,
        scope: {
            ngModel: '=',
            ebForm: '=',
            ebDisabled: "=?",
            ebGeorgian: "=?",
            ebAccountNo: "=?",
            ebEmail: "=?",
            placeholder: "@",
            ebInputStyle: "@",
            ebMaxLength: "@?",
            ebName: "@",
            validateMessage:"@",
            form: '=formData', // nie używane - do sprawdzenia
            ebRows: "=?",
            ebMaxlength: "@?",
            ebRequired: "=?",
            ebResizable: "=?",
            callback: "&"
        },
        templateUrl: pathService.generateTemplatePath('halyk-authentication') + '/directives/cbOneLineTextareaExternal/cbOneLineTextareaExternal.html',
        link: function postLink(scope, element, attrs) {


            scope.ebRequired = angular.isDefined(scope.ebRequired) ? scope.ebRequired : true;

            // scope.latin =
            //     ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z",
            //      "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z",
            //      "0","1","2","3","4","5","6","7","8","9","/","-","?",":","(",")",".",",","'","+"];
            // scope.georgian =["ა","ბ","გ","დ","ე","ვ","ზ","თ","ი","კ","ლ","მ","ნ","ო","პ","ჟ","რ","ს","ტ","უ","ფ","ქ","ღ","ყ","შ","ჩ","ც","ძ","წ","ჭ","ხ","ჯ","ჰ"];

            scope.latin = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            scope.digits = "0123456789";
            scope.georgian ="აბგდევზთიკლმნოპჟრსტუფქღყშჩცძწჭხჯჰ";
            scope.email = ".-+@".concat ( scope.digits ).concat ( scope.latin );
            scope.defaultDictionary = scope.latin.concat ( scope.digits ).concat ( " /-?:().,'+" );
            scope.accountNo = scope.latin.concat ( scope.digits );


            scope.ebGeorgian = angular.isDefined(scope.ebGeorgian) ? scope.ebGeorgian :  false;
            scope.showDelCharMsg = false;




/*            if (angular.isDefined(attrs.validateMessage)) {
                scope.validateMessage = attrs.validateMessage;
            } else {
                scope.validateMessage = translate.property("platform.component.one_line.title.validate");
            }

            scope.delCharMsg = translate.property("platform.component.one_line.title.cyrillic");
            if ( !scope.ebGeorgian ){
                scope.delCharMsg = translate.property("platform.component.one_line.title.cyrillic.geo");
            }*/

            scope.linesNumber = 4;
            if(scope.ebRows) {
                scope.linesNumber = scope.ebRows;
            } else {
                scope.ebRows = scope.linesNumber
            }
            if(scope.ebResizable) {
                scope.linesNumber = 1;
            }

            if (scope.ebName === undefined) {
                scope.ebName = "textarea";
            }
            if(scope.ebMaxLength == undefined) {
                scope.ebMaxLength = 500;
            }

            var textArea = element.find('textarea');

            scope.$watch('ngModel', function(newVal, oldVal) {
                if (newVal != null) {
                    if (angular.isArray(scope.ngModel)) {
                        textArea.val(scope.ngModel.join('\n'));
                    }
                }
            });

            scope.$watch('ebMaxLength', function(newVal, oldVal) {
                if ( newVal != null && newVal != oldVal && +newVal < +oldVal) {
                      if ( textArea.val().length > +newVal ){
                          textArea.val( textArea.val().substr( 0,+newVal ) );
                      }

                }
            });


            scope.textAreaBlur = function(){
               var text = textArea.val().toUpperCase();
               var result = "";
                for ( var i = 0; i < text.length; i++ ){
                    // var htmlCode = text[i].charCodeAt(0);
                    // var cyrillic = (htmlCode >=1024 && htmlCode <=1279);
                    // var georgian = !scope.ebGeorgian && (htmlCode>=4256 && htmlCode <= 4351);

                    var allow = scope.defaultDictionary.indexOf ( text [ i ] ) != -1;

                    if ( scope.ebGeorgian == true ) {
                        allow = allow || scope.georgian.indexOf ( text [ i ] ) != -1;
                    }
                    if ( scope.ebEmail == true ) {
                       // allow =  scope.email.indexOf ( text [ i ] ) != -1;
                        allow =  true;
                    }

                    if ( scope.ebAccountNo == true ) {
                        allow =  scope.accountNo.indexOf ( text [ i ] ) != -1;
                    }
                    if ( allow ){
                        result += text[i];
                    } else {
                        scope.showDelCharMsg = true;
                    }
                }
                scope.timer = $interval(function(){
                    scope.showDelCharMsg = false;
                    $interval.cancel(scope.timer);
                },5000);
                textArea.val( result );
               scope.callback({});
            };

            textArea.bind('change blur paste', function(event) {
                $timeout(function() {
                    var text = textArea.val().trim();
                    var parsedText = $filter('ebTextareaFilter')(text);
                    textArea.val(parsedText);
                    scope.ngModel = text.split('\n');
                })
            });

            textArea.bind('keydown', function(event) {
                var keyCodesArray = [8, 37, 38, 39, 40, 46, 16, 17, 20, 144, 9]; // backspace, arrows, delete, ctrl, shift, capslock, numlock, tab

                if (keyCodesArray.indexOf(event.keyCode) == -1) {

                    if (event.keyCode == 13) {
                        var text = textArea.val().trim();
                        var parsedText = $filter('ebTextareaFilter')(text);
                        var height = text.split('\n').length;
                        if (height > scope.ebRows-1) {
                            event.preventDefault();
                        } else {
                            textArea.val(parsedText);
                        }
                    } else {
                        var text = textArea.val();
                        var lines = text.split('\n');
                        var height = lines.length;
                        var selStart = textArea[0].selectionStart;
                        var selEnd = textArea[0].selectionEnd;
                        var currentLineNo = text.substr(0, selStart).split('\n').length - 1;

                        if (lines[currentLineNo].length > 500 && selStart == selEnd) {
                            if (height < scope.ebRows) {
                                var subText = text.substr(0, selStart) + '\n' + text.substr(selStart, text.length);
                                textArea.val(subText);
                                if (selStart == 0 || text.substr(selStart - 1, 1) == '\n') {
                                    textArea[0].setSelectionRange(selStart, selEnd);
                                } else {
                                    textArea[0].setSelectionRange(selStart + 1, selEnd + 1);
                                }
                            } else {
                                textArea.val(text.substr(0, text.length));
                                event.preventDefault();
                            }
                        }
                    }
                    if(height >= 2) {
                        if(scope.ebResizable) {
                            scope.linesNumber = height > scope.ebRows ? scope.ebRows : height;
                        }
                    }
                }
            });
        }
    };
});
