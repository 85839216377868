'use strict';

halykAuthenticationModule.controller('cardToCardFailController', function($scope, $http, $cookies, $window) {

    $scope.status='default';


    $scope.onSubmit = function() {
        $window.location.href='/frontend-web/app/index.html'
    }

    $scope.checkP2PStatus = function (){
        if(!$cookies.get('transId')){
            $scope.status='';
            return;
        }

        var req = {
            method: 'GET',
            url: '/frontend-web/api/rstyle_internal/get/p2p_status_check.json',
            headers: {'Content-Type': 'application/json'},
            params:{ tranId:$cookies.get('transId')}
        }

        $http(req).then(function(resp){
            if(angular.isDefined(resp.data.data)){
                $scope.status=resp.data.data;
            } else{
                $scope.status='ERROR';
            }
        });
    }

    $scope.checkP2PStatus();
});

