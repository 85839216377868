'use strict';

halykAuthenticationModule.directive('cbHeader', function( pathService, cbBrandingService) {
	return {
        restrict: 'EA',
        scope: true,
        templateUrl: pathService.generateTemplatePath("halyk-authentication") + '/directives/cbHeader/cbHeader.html',
        controller: function ($scope) {
            $scope.logoUrl = cbBrandingService.getLogoUrl();
            $scope.logoMobileUrl = cbBrandingService.getLogoMobileUrl();
            $scope.faviconUrl = cbBrandingService.getFaviconUrl();
        }
    };
});