'use strict';

halykAuthenticationModule.controller('registrationUnsuccessController', function($scope, $state,translate,viewStateService) {

	if (angular.isUndefined(viewStateService.getInitialState("registrationUnsuccess"))) {
		$state.go("login");
		return;
	}

	$scope.result = viewStateService.getInitialState("registrationUnsuccess");

	$scope.message = translate.property($scope.result.resultCode);

	$scope.onSubmit = function() {
		$state.go("registration_step_one");
	}
	   
});

