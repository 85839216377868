'use strict';
halykAuthenticationModule.controller('passwordForgotConfirmController', function ($scope, $rootScope, $state, passwordForgotViewState, resetPasswordService) {

    if(angular.isUndefined(passwordForgotViewState.current.formData)){
        $state.go("passwordForgot");
        return;
    }
    var formData = angular.extend({}, passwordForgotViewState.current.formData, {checkUserOnly: false});


    $scope.onSubmit = function () {

            return resetPasswordService.reset(formData).then(function (result) {
                console.log("was success" + result);
                passwordForgotViewState.current.isSucess = result;
                $state.go("passwordForgotResult");
            }).catch(function (errorMessage) {
                console.error("was errorMessage", errorMessage);
                passwordForgotViewState.current.isSucess = false;
                $state.go("passwordForgotResult");
            });
    };

    $scope.backToPrev = function () {
        $rootScope.isBackTransition = true;
        $state.go("passwordForgot");
    };

});
