'use strict';

var halykAuthenticationModule = angular.module('halyk-authentication', [
    'halyk-ebanking-shared',
    'ebPlatformModule'

]).config(function(translationsLoaderProvider, ebThemingProvider, $stateProvider, pathServiceProvider, $urlRouterProvider) {
    
    webComponentRegistry['halyk-authentication'].simpleName = "authentication";

    translationsLoaderProvider.addTranslationsPath(pathServiceProvider.generateRootPath("halyk-authentication") + "/i18n/messages_{{language}}.json");

    ebThemingProvider.setDefaultTheme('halyk');

    sessionStorage.setItem('afterLogin', true);

    $urlRouterProvider.otherwise("login");
    $stateProvider
    .state('login', {
        url: "/login",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/loginView.html",
        resolve: {
            loginResolvedData: function (authenticationService) {
                return authenticationService.prepareStep1();
            }
        },
        controller: "loginController"  //payment_accept_success
    }).state('payment', {
        url: "/payment/:paymentCode",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/paymentView.html",
        controller: "paymentController",
    }).state('payment_accept_success', {
        url: "/payment_accept_success",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/paymentAcceptSuccessView.html",
        controller: "paymentController",
    }).state('payment_reject_success', {
        url: "/payment_reject_success",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/paymentRejectSuccessView.html",
        controller: "paymentController",
    }).state('registration_step_one', {
        url: "/registration",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/registrationView.html",
        controller: "registrationController"
    }).state('registration_step_two', {
        url: "/registration_step_two",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/registrationViewStepTwo.html",
        controller: "registrationStepTwoController"
    }).state('registration_step_three', {
        url: "/registration_step_three",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/registrationViewStepThree.html",
        resolve: {
            resolvedSetPasswordConfigData: function (authenticationService) {
                return {
                    "minLength" : 8,
                    "maxLength" : 80,
                    "digitRequired" : true,
                    "charRequired" : true,
                    "largeLetterRequired" : true,
                    "smallLeterRequired" : true,
                    "specialCharacter" : "!@#$%^&*()_+|}{<>?”:`-= [];’./"
                };
            }
        },
        controller: "registrationStepThreeController"
    }).state('registrationSuccess', {
        url: "/registrationSuccess",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/registrationSuccessView.html",
        controller: "registrationSuccessController"
    }).state('registrationUnsuccess', {
        url: "/registrationUnsuccess",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/registrationUnsuccessView.html",
        controller: "registrationUnsuccessController"
    }).state('cardToCardSuccess', {
        url: "/m/s",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/cardToCardSuccessView.html",
        controller: "cardToCardSuccessController"
    }).state('cardToCardFail', {
        url: "/m/f",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/cardToCardFailView.html",
        controller: "cardToCardFailController"
    }).state('password', {
        url: "/password",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordView.html",
        controller: "passwordController"
    }).state('passwordMasked', {
        url: "/passwordMasked",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordMaskedView.html",
        controller: "passwordMaskedController"
    }).state('passwordToken', {
        url: "/passwordToken",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordTokenView.html",
        controller: "passwordTokenController"  
    }).state('passwordTokenNext', {
        url: "/passwordTokenNext",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordTokenNextView.html",
        resolve: {
            resolvedNextTokenCodeConfigData: function (authenticationService) {
                return authenticationService.prepareStep3NextTokenCode();
            }
        },
        controller: "passwordTokenNextController"  
    }).state('passwordSmsCode', {
        url: "/passwordSmsCode",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordSmsCodeView.html",
        controller: "passwordSmsCodeController"
    }).state('passwordChange', {
        url: "/passwordChange",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordChangeView.html",
        resolve: {
            resolvedChangePasswordConfigData: function (authenticationService) {
                return {
                    "minLength" : 8,
                    "maxLength" : 80,
                    "digitRequired" : true,
                    "charRequired" : true,
                    "largeLetterRequired" : true,
                    "smallLeterRequired" : true,
                    "specialCharacter" : "!@#$%^&*()_+|}{<>?”:`-= [];’./"
                };
            }
        },
        controller: "passwordChangeController"
    }).state('passwordForgot', {
        url: "/passwordForgot",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordForgotView.html",
        resolve: {
            loginResolvedData: function (authenticationService) {
                return authenticationService.prepareStep1();
            }
        },
        controller: "passwordForgotController"
    }).state('passwordForgotConfirm', {
        url: "/passwordForgotConfirm",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordForgotConfirmView.html",
        controller: "passwordForgotConfirmController"
    }).state('passwordForgotResult', {
        url: "/passwordForgotResult",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/passwordForgotResultView.html",
        controller: "passwordForgotResultController"
    }).state('expired', {
        url: "/expired",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/expiredView.html",
        controller: "expiredController"  
    }).state('logout', {
        url: "/logout",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/logoutView.html",
        controller: "logoutController"  
    }).state('coexpired', {
        url: "/coexpired",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/coexpiredView.html",
        controller: "expiredController"
    }).state('cologout', {
        url: "/cologout",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/cologoutView.html",
        controller: "logoutController"
    }).state('cologin', {
        url: "/cologin",
        templateUrl: pathServiceProvider.generateTemplatePath("halyk-authentication") + "/views/cologinView.html",
        controller: "logoutController"
    });

}).run(function($state, $window, $location, $rootScope) {

    if($location.$$path !== '/m/s' &&  $location.$$path !== '/m/f'){
        // $state.go('login');
        $rootScope.p2pClass = '';
    } else{
        $rootScope.p2pClass = 'p2p';
    }

    // initialise google analytics
    if ($window.ga) {
        $window.ga('create', 'UA-179039009-1', 'auto');

        // track pageview on state change
        $rootScope.$on('$stateChangeSuccess', function (event) {
            $window.ga('send', 'pageview', $location.path());
        });
        window.open = function (openFunc) {
            return function (url) {
                if (url) {
                    $window.ga('send', 'pageview', url);
                }
                return openFunc.call(window, url);
            };
        }(window.open);
    }
});

