'use strict';

halykAuthenticationModule.controller('halyk.authentication.LayoutController', function($scope, localStore, pathService, translate) {

    $scope.getLayoutsPath = function() {
        return pathService.generateTemplatePath("halyk-authentication");
    };

    document.querySelector("#mainAuthenticationTagTitle").innerHTML=translate.property("db.auth.page_title");

});
