'use strict';

halykAuthenticationModule.controller('registrationController', function ($scope, $state, registrationService, translate, viewStateService) {

    $scope.formData = {};
    $scope.personalIdMaxLength = 11;
    $scope.phoneNumberMaxLength = 9;

    viewStateService.resetAllStates();

    $scope.radioboxItems = [
        {
            label: translate.property("db.registration.with.mail"),
            value: true,
            default: true
        },
        {
            label: translate.property("db.registration.with.card"),
            value: false,
            default: false
        }
    ];


    $scope.onSubmit = function (form) {
        $scope.registrationForm.submitted = true;
        if ($scope.registrationForm.$valid && isAllFormFieldsIsNotNull()) {
            registrationService.getCostumerSelfSearch($scope.formData).then(function (response){
                if (response.success) {
                    viewStateService.setInitialState("registration_step_two", $scope.formData);
                    $state.go("registration_step_two");
                } else {
                    if (response.resultCode === "register.again.true"){
                        $scope.formData.withMail = true;
                        $scope.formData.isActivation = true;
                        viewStateService.setInitialState("registration_step_two", $scope.formData);
                        $state.go("registration_step_two");
                    } else {
                        viewStateService.setInitialState("registrationUnsuccess", {resultCode: response.resultCode});
                        $state.go("registrationUnsuccess");
                    }
                }
            }, function (errorMessage) {
                viewStateService.setInitialState("registrationUnsuccess", {resultCode: errorMessage});
                $state.go("registrationUnsuccess");
            });

        } else {
            $scope.registrationForm.$setDirty();
            $scope.fieldsRequired = true;
        }
    };

    var clearFormFields = function() {
        if (angular.isDefined($scope.formData)) {
            $scope.formData = null;
        }
        $scope.authForm.j_password.$setDirty();
    };

    var isAllFormFieldsIsNotNull = function () {
        if ($scope.formData.personalId == null || $scope.formData.phoneNumber == null ||  $scope.formData.withMail == null) {
            return false;
        } else {
            return true;
        }
    };


    var validateEmail = function () {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test($scope.formData.email);
    };

    $scope.backToPrev = function () {
        $state.go("login");
    };

});

