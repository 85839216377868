'use strict';

halykAuthenticationModule.controller('passwordChangeController',
    function ($scope, $state, $rootScope, logonViewState, authenticationService,  formService, $log, resolvedChangePasswordConfigData ) {

        if ($state.current.name == "expired" || $state.current.name == "logout") {
            $state.go("login");
            return;
        }

        $scope.currentLogin = logonViewState.current.login;
        if (angular.isDefined(logonViewState.current.authResult)) {
            $scope.authStatus = logonViewState.current.authResult.status;
        }

        $scope.authenticationPolicy = logonViewState.current.pageConfig;

        $scope.backToPrev = function () {
            $rootScope.isBackTransition = true;
            $state.go("login");
        };

        $scope.passwordPolicies = resolvedChangePasswordConfigData;

        function resetPassData() {
            $scope.passData = {
                pw2: null,
                pw3: null
            };
            $scope.errors = [];
        }

        function resetClasses(){
            $scope.policyClasses={
                length: '',
                digit: '',
                upper: '',
                lower: '',
                special: ''
            };
        }

        resetPassData();
        resetClasses();

        $scope.preventDefault = function (event) {
            event.preventDefault();
        };


        function resetForm() {
            resetPassData();
        }

        $scope.$watch('passData.pw2', function(newVal, oldVal) {
            if ($scope.errors.length > 0) {
                $scope.errors = [];
            }
            if ( newVal != oldVal ){
                resetClasses();
            }
        });

        function changePassword() {
            $rootScope.isBackTransition = false;

            $scope.authStatus = null;

            authenticationService.changePassword($scope.passData.pw2)
                .then(function (data) {
                    $log.debug("was success" + data);
                    $scope.changeStatus = data.status;
                    if (data.status == 'NEW_PASSWORD_INCORRECT') {
                        $scope.errors.push("incorrect_new_password");
                        //resetForm();
                    } else if (data.status == 'OLD_PASSWORD_INCORRECT') {
                        $scope.errors.push("incorrect_old_password");
                        //resetForm();
                    }
                }, function (errorMessage) {
                    $log.error("was errorMessage" + errorMessage);
                    resetForm();
                });
        }

        function passwordValid( password  ){
            $scope.policyClasses={
                length: 'success-password',
                digit: 'success-password',
                upper: 'success-password',
                lower: 'success-password',
                special: 'success-password'
            };
            var valid = true;
            var digitReg = new RegExp('[0-9]+');
            var capitalReg = new RegExp('[A-Z]+');
            var lowerReg = new RegExp('[a-z]+');
            var specChar = $scope.passwordPolicies.specialCharacter;
            if ( password.length < $scope.passwordPolicies.minLength ) {
                $scope.policyClasses.length="wrong-password";
                valid = false;
            } else if ( password.length > $scope.passwordPolicies.maxLength ) {
                $scope.policyClasses.length="wrong-password";
                valid = false;
            }
            if ( $scope.errorMsg == null ) {
                if ( $scope.passwordPolicies.digitRequired && !digitReg.test( password ) ){
                    $scope.policyClasses.digit="wrong-password";
                    valid = false;
                }
                if ( $scope.passwordPolicies.largeLetterRequired && !capitalReg.test( password ) ){
                    $scope.policyClasses.upper="wrong-password";
                    valid = false;
                }
                if ( $scope.passwordPolicies.largeLetterRequired && !lowerReg.test( password ) ){
                    $scope.policyClasses.lower="wrong-password";
                    valid = false;
                }
                if ( $scope.passwordPolicies.charRequired  ){
                    var notExist = true;
                    for( var i = 0; i < password.length; i++  ){
                        if ( specChar.indexOf( password[i] ) > -1 ){
                            notExist = false;
                            break;
                        }
                    }
                    if ( notExist ){
                        $scope.policyClasses.special="wrong-password";
                        valid = false;
                    }
                }

            }
            return valid;
        }

        $scope.submit = function (form) {
            console.log(form);
            resetClasses();
            if (!form.$valid ) {
                formService.dirtyFields(form);
                return;
            }
            if (  !passwordValid( $scope.passData.pw2 ) ){
                return;
            }
            changePassword();
        };

    });

