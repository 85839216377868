'use strict';


if(!ebProfile(ebProfiles.PROTOTYPE)) 
halykAuthenticationModule.controller('passwordMaskedController', function($scope, $rootScope, $state, $timeout, focus, logonViewState, authenticationService) {


	if($state.current.name == "expired" || $state.current.name == "logout" || angular.isUndefined(logonViewState.current.pageConfig)){
		$state.go("login");
		return;
	}

	var maskFormat = logonViewState.current.pageConfig.maskIn01Format;
	var currentLogin = logonViewState.current.login;

	var passwordTemplate = {
        length: maskFormat.length,
        disabledCharacters: []
	};

	if(maskFormat != null) {
		for(var i = 0; i < maskFormat.length; i++){
			if(maskFormat[i] == '0'){
                passwordTemplate.disabledCharacters.push(i);
			}
	    }
	}

    var getFirstInputIndex = function(index){
        var nextIndex = (index != null ? index + 1 : 0);
        while(passwordTemplate.disabledCharacters.indexOf(nextIndex) != -1){
            nextIndex++;
        }
       return nextIndex;
    };

    var focusOnInput = function(index) {
        if(index >= 0 && index <= passwordTemplate.length) {
            focus("focus-input-char-" + index);
        }
    };

    var joinMaskedPassword = function() {
        var result = "";
        for(var i = 0; i < $scope.passwordArray.length; i++){
            if(angular.isDefined($scope.passwordArray[i])){
                result += $scope.passwordArray[i];
            }
        }

        return result;
    };

    var clearInvalidForm = function() {
        for(var idx = 0; idx < passwordTemplate.length; idx++) {
            $scope.passwordArray[idx] = "";
            $scope.authForm['innerForm_' + idx]['j_password'].$setDirty();
        }

        var firstIndex = getFirstInputIndex();
        focusOnInput(firstIndex);
    };

    $scope.inputDisabled = '0';
    $scope.passwordArray = new Array(passwordTemplate.length);

    $scope.passwordCharChange = function(index){
        if($scope.passwordArray == null || $scope.passwordArray[index] == null || $scope.passwordArray[index].length == 0) {
            return;
        }

        var nextIndex = getFirstInputIndex(index);
        focusOnInput(nextIndex);
    };

    $scope.onPasswordInputKeyDown = function(event, index){
        var BACKSPACE = 8;
        var ESCAPE = 27;

        if(event.keyCode == BACKSPACE && ($scope.passwordArray[index] == null || ($scope.passwordArray[index] != null &&$scope.passwordArray[index].length == 0))){
            var prevIndex = index-1;
            while(passwordTemplate.disabledCharacters.indexOf(prevIndex) != -1){
                prevIndex--;
            }
            focusOnInput(prevIndex);
        } else if(event.keyCode == ESCAPE){
        	$scope.backToPrev();
        }
    };

    $scope.isCharDisabled = function(index){
        return (passwordTemplate.disabledCharacters.indexOf(index) != -1);
    };

    $scope.isCredentialsIncorrect = function() {
        return ($scope.authStatus == "CREDENTIALS_INCORRECT");
    };

    $scope.isAccessBlocked = function(){
        return ($scope.authStatus == "CREDENTIALS_CORRECT_BUT_ACCESS_BLOCKED");
    }
    
    $scope.onSubmit = function() {
        $scope.authForm.submitted = true;

    	if($scope.authForm.$valid) {
            $rootScope.isBackTransition = false;

	    	return authenticationService.authenticate(currentLogin, joinMaskedPassword())
    		.then(function(data){
    			console.log("was success" + data);

    			$scope.authForm.$setPristine();
    			$scope.authStatus = data.status;

                if($scope.isCredentialsIncorrect() || $scope.isAccessBlocked()) {
                    clearInvalidForm();
                }
            }, function(errorMessage){ 
            	console.log("was errorMessage" + errorMessage); 

                $scope.authForm.$setPristine();
                clearInvalidForm();
            });
    	} else {
    		$scope.authForm.$setDirty();
            $scope.authStatus = "";
            clearInvalidForm();
    	}
    };
	  
    $scope.backToPrev = function(){
        $rootScope.isBackTransition = true;
        $state.go("login");
    };

	$scope.preventDefault = function(event) {
        event.preventDefault();
    };

    focusOnInput(getFirstInputIndex());
});


if(ebProfile(ebProfiles.PROTOTYPE)) 
halykAuthenticationModule.controller('passwordMaskedController', function($scope, $rootScope, $state, logonViewState, focus, authenticationService) {
    var maskFormat = [1,0,0,0,1,1,1,1,0];
    var currentLogin = logonViewState.current.login;

    var passwordTemplate = {
        length: maskFormat.length,
        disabledCharacters: []
    };
    
    if(maskFormat != null) {
        for(var i = 0; i < maskFormat.length; i++){
            if(maskFormat[i] == '0'){
                passwordTemplate.disabledCharacters.push(i);
            }
        }
    }
   
    var getFirstInputIndex = function(index){
        var nextIndex = (index != null ? index + 1 : 0);
        while(passwordTemplate.disabledCharacters.indexOf(nextIndex) != -1){
            nextIndex++;
        }
       return nextIndex;
    };

    var focusOnInput = function(index) {
        if(index >= 0 && index <= passwordTemplate.length) {
            focus("focus-input-char-" + index);
        }
    }

    $scope.passwordArray = new Array(passwordTemplate.length);

    $scope.onSubmit = function() {
        $window.location.href = "../index.html";
    };

    $scope.backToPrev = function(){
        $rootScope.isBackTransition = true;
        $state.go("login");
    };

    focusOnInput(getFirstInputIndex());
});
