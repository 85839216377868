'use strict';

halykAuthenticationModule.directive('cbFooter', function(pathService) {
	return {
        restrict: 'EA',
        replace: false,
        scope: false,
        transclude: true,
        templateUrl: pathService.generateTemplatePath("halyk-authentication") + '/directives/cbFooter/cbFooter.html'
    };
});