'use strict';
halykAuthenticationModule.controller('passwordForgotResultController', function ($scope, $rootScope, $state, passwordForgotViewState, resetPasswordService) {

    if(angular.isUndefined(passwordForgotViewState.current.isSucess)){
        $state.go("passwordForgot");
        return;
    }
    $scope.isSuccess = passwordForgotViewState.current.isSucess;


    $scope.onSubmit = function () {
        passwordForgotViewState.reset();
        $state.go("login");
    };

    $scope.backToPrev = function () {
        $rootScope.isBackTransition = true;
        $state.go("passwordForgot");
    };

});
