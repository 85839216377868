halykAuthenticationModule.service('registrationService', function($rootScope, httpService, gate) {

    'use strict';
    
    return {
        registerUser: function (params) {
            var registerUserUrl = '/api/bo_user/actions/register.json';
            return httpService.post(registerUserUrl, params);
        },
        selfActivateUser: function (params) {
            var registerUserUrl = '/api/bo_user/actions/self_activate.json';
            return httpService.post(registerUserUrl, params);
        },
        getCostumerSelfSearch: function (params) {
           var custumerSearchUrl = '/api/registration/search/customer_search_registration.json';
           return httpService.post(custumerSearchUrl,params);
        },

        checkUsernameDuplicates: function (params) {
            var checkUsernameDuplicatesPath = '/api/bo_login/actions/check_login_duplicates_unauthorized.json';
            return httpService.post(checkUsernameDuplicatesPath, params);
        },
        validateCardInfo: function (params) {
            var custumerSearchUrl = '/api/registration/search/customer_card_search.json';
            return httpService.post(custumerSearchUrl,params);
        },
        validateSmsMail: function (params) {
            var validateSmsUrl = "/api/registration/actions/validate_otp_mail.json";
            return httpService.post(validateSmsUrl,params);
        },
        validateSmsPhone: function (params) {
            var validateSmsUrl = "/api/registration/actions/validate_otp_phone.json";
            return httpService.post(validateSmsUrl,params);
        },
        resendSmsCode: function (params) {
            var resendSmsUrl = "/api/registration/actions/resend_otp_registration.json";
            return httpService.post(resendSmsUrl,params);
        },
        sendApplication: function (params) {
            var sendApplicationUrl = "/api/registration/actions/submit_new_user_statement.json";
            return httpService.post(sendApplicationUrl,params);
        }
    }
});