'use strict';

function serializeData(data) {
    // If this is not an object, defer to native stringification.
    if (!angular.isObject(data)) {
        return ((data == null) ? "" : data.toString());
    }

    var buffer = [];

    // Serialize each key in the object.
    for (var name in data) {
        if (!data.hasOwnProperty(name)) {
            continue;
        }

        var value = data[name];

        buffer.push(
            encodeURIComponent(name) + "=" + encodeURIComponent((value == null) ? "" : value)
        );
    }

    // Serialize the buffer and clean it up for transportation.
    var source = buffer.join("&").replace(/%20/g, "+");
    return (source);
}

if (!ebProfile(ebProfiles.PROTOTYPE))
    halykAuthenticationModule.factory('authenticationService', ['$resource', '$q', '$state', '$location', '$log', '$rootScope', '$window', '$http', 'logonViewState', '$timeout', 'cbNotificationService',
        function ($resource, $q, $state, $location, $log, $rootScope, $window, $http, logonViewState, $timeout, cbNotificationService) {

            return {
                prepareStep1: function () {

                    var loginData = {step: "1"};
                    var deferred = $q.defer();
                    $timeout(() => {
                        document.cookie = "JSESSIONID=;domain=myhalyk.ge;path=/frontend-web;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                        document.cookie = "XSRF-TOKEN=;domain=myhalyk.ge;path=/frontend-web;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                    })
                    $http({
                        method: 'POST',
                        url: 'j_spring_security_check',
                        data: serializeData(loginData), // pass in data as strings
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).success(function (data) {
                        console.log("->STEP1:" + angular.toJson(data));
                        $rootScope.serverError = false;

                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        console.log("->STEP1:" + errorMessage);
                        $rootScope.serverError = true;

                        deferred.reject(errorMessage);
                    });
                    return deferred.promise;
                },
                goToStep2: function (login) {

                    var formData = {step: "1to2", j_username: login};

                    logonViewState.current.login = login;

                    var deferred = $q.defer();

                    console.log("BEFORE SEND STEP1->STEP2");
                    $http({
                        method: 'POST',
                        url: 'j_spring_security_check',
                        data: serializeData(formData),  // pass in data as strings
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
                    })
                        .success(function (data) {
                            console.log("STEP1->STEP2:" + angular.toJson(data));
                            $rootScope.serverError = false;

                            logonViewState.current.pageConfig = data;
                            if (angular.isDefined(logonViewState.current.pageConfig)) {
                                if (logonViewState.current.pageConfig.method == 'PASSWORD') {
                                    $location.url("/password");
                                } else if (logonViewState.current.pageConfig.method == 'MASKED_PASSWORD') {
                                    $location.url("/passwordMasked");
                                } else if (logonViewState.current.pageConfig.method == 'TOKEN_CODE_AND_PASSWORD') {
                                    $location.url("/passwordToken");
                                }
                            }
                        })
                        .error(function (errorMessage) {
                            console.log("STEP1->STEP2:" + errorMessage);
                            $rootScope.serverError = true;

                            deferred.reject(errorMessage);
                        });

                    return deferred.promise;
                },
                authenticate: function (login, password) {

                    var formData = {step: "2", j_username: login, j_password: password};

                    var deferred = $q.defer();

                    logonViewState.current.password = password;

                    $http({
                        method: 'POST',
                        url: 'j_spring_security_check',
                        data: serializeData(formData),  // pass in data as strings
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
                    }).success(function (data) {
                        console.log("STEP2->AUTH:" + angular.toJson(data));
                        $rootScope.serverError = false;

                        logonViewState.current.authResult = data;
                        if (data.status == "CREDENTIALS_CORRECT") {
                            $rootScope.isBackTransition = false;
                            $window.location.href = logonViewState.current.authResult.redirectURL;
                        } else if (data.status == "CREDENTIALS_CORRECT_BUT_SEC_CREDS_REQUIRED" || data.status == "CREDENTIALS_CORRECT_BUT_NEXT_SMS_CODE_REQUIRED") {
                            $rootScope.isBackTransition = false;
                            $location.url("/passwordSmsCode");
                        } else if (data.status == "CREDENTIALS_CORRECT_BUT_PASSWORD_HAS_TO_BE_CHANGED") {
                            $rootScope.isBackTransition = false;
                            $location.url("/passwordChange");
                        } else if (data.status == "CREDENTIALS_CORRECT_BUT_NEXT_TOKEN_CODE_HAS_TO_BE_PASSED") {
                            $rootScope.isBackTransition = false;
                            $location.url("/passwordTokenNext");
                        } else if (data.status === "CREDENTIALS_CORRECT_BUT_ACCESS_BLOCKED") {
                            $rootScope.isBackTransition = false;
                        } else { //CREDENTIALS_INCORRECT
                            $rootScope.isBackTransition = true;
                            //$location.url("/login");
                        }

                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        console.log("STEP2->AUTH:" + errorMessage);
                        $rootScope.serverError = true;

                        deferred.reject(errorMessage);
                    });

                    return deferred.promise;
                },
                authenticateSMS: function (login, password) {

                    var formData = {step: "3s", j_username: login, j_next_sms_code: password};

                    var deferred = $q.defer();

                    $http({
                        method: 'POST',
                        url: 'j_spring_security_check',
                        data: serializeData(formData),  // pass in data as strings
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
                    }).success(function (data) {
                        console.log("STEP3s->AUTH:" + angular.toJson(data));
                        $rootScope.serverError = false;

                        logonViewState.current.authResult = data;

                        $rootScope.isBackTransition = false;
                        if (data.status == "CREDENTIALS_INCORRECT") {
                            //do nothing, show error
                        } else if (data.status == "CREDENTIALS_CORRECT_BUT_PASSWORD_HAS_TO_BE_CHANGED") {
                            $location.url("/passwordChange");
                        } else {
                            $window.location.href = logonViewState.current.authResult.redirectURL;
                        }

                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        console.log("STEP2->3s:" + errorMessage);
                        $rootScope.serverError = true;

                        deferred.reject(errorMessage);
                    });

                    return deferred.promise;
                },
                prepareStep3ChangePassword: function () {

                    var formData = {step: "2to3p"};

                    var deferred = $q.defer();

                    $http({
                        method: 'GET',
                        url: '/frontend-web/api/customer/get/get_password_settings.json',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).success(function (data) {

                        console.log("STEP2->STEP3password:" + angular.toJson(data));
                        $rootScope.serverError = false;

                        deferred.resolve(data);
                        return;
                    }).error(function (errorMessage) {
                        console.log("STEP2->STEP3password:" + errorMessage);
                        $rootScope.serverError = true;

                        deferred.reject(errorMessage);
                        return;
                    });

                    return deferred.promise;
                },
                changePassword: function (password) {

                    var formData = {step: "3p", j_new_password: password};

                    var deferred = $q.defer();

                    $http({
                        method: 'POST',
                        url: 'j_spring_security_check',
                        data: serializeData(formData),  // pass in data as strings
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
                    }).success(function (data) {
                        console.log("STEP3->CHANGE:" + angular.toJson(data));
                        $rootScope.serverError = false;

                        logonViewState.current.changeResult = data;
                        if (data.status == "NEW_PASSWORD_CORRECT") {
                            $rootScope.isBackTransition = false;
                            var logonMethod = logonViewState.current.pageConfig.method;
                            if (logonMethod == 'TOKEN_CODE_AND_PASSWORD')
                                $location.url("/passwordTokenNext");
                            else
                                $window.location.href = data.redirectURL;
                            //$location.url("/success");
                        } else if (data.status == "NEW_PASSWORD_INCORRECT") {
                            //stay at the same page
                        } else if (data.status == "CREDENTIALS_CORRECT_BUT_NEXT_TOKEN_CODE_HAS_TO_BE_PASSED") {
                            $rootScope.isBackTransition = false;
                            $location.url("/passwordTokenNext");
                        } else if (data.status == "OLD_PASSWORD_INCORRECT") {
                            cbNotificationService.showMessageDialog(
                                {
                                    title: 'common.toast.success.title'
                                    , message: 'db.auth.passwordChange.success'
                                    , ok: 'OK'
                                }
                            );
                            $state.go("login");
                        } else {
                            $location.url("/login");
                        }

                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        console.log("STEP3->CHANGE:" + errorMessage);
                        $rootScope.serverError = true;

                        deferred.reject(errorMessage);
                    });

                    return deferred.promise;
                },
                prepareStep3NextTokenCode: function () {

                    var formData = {step: "2to3t"};

                    var deferred = $q.defer();

                    $http({
                        method: 'POST',
                        url: 'j_spring_security_check',
                        data: serializeData(formData), // pass in data as strings
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).success(function (data) {
                        console.log("STEP2->STEP3token:" + angular.toJson(data));
                        $rootScope.serverError = false;

                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        console.log("STEP2->STEP3token:" + errorMessage);
                        $rootScope.serverError = true;

                        deferred.reject(errorMessage);
                    });
                    return deferred.promise;
                },
                nextTokenCode: function (nextTokenCode) {

                    var formData = {step: "3t", j_next_token_code: nextTokenCode};

                    var deferred = $q.defer();

                    $http({
                        method: 'POST',
                        url: 'j_spring_security_check',
                        data: serializeData(formData),  // pass in data as strings
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  // set the headers so angular passing info as form data (not request payload)
                    }).success(function (data) {
                        console.log("STEP3->NEXTTOKEN:" + angular.toJson(data));
                        $rootScope.serverError = false;

                        logonViewState.current.nextTokenCodeResult = data;

                        if (data.status == "NEXT_TOKEN_CODE_CORRECT") {
                            $rootScope.isBackTransition = false;
                            $window.location.href = data.redirectURL;
                            //$location.url("/success");
                        } else if (data.status == "NEXT_TOKEN_CODE_INCORRECT") {
                            //stay at the same page
                        } else {
                            $location.url("/login");
                        }

                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        console.log("STEP3->NEXTTOKEN:" + errorMessage);
                        $rootScope.serverError = true;

                        deferred.reject(errorMessage);
                    });

                    return deferred.promise;
                },
                getIncomingPayment: function (paymentCode) {
                    var formDataExternal = {paymentCode: paymentCode};
                    var deferred = $q.defer();
                    $http({
                        method: 'POST',
                        url: '/frontend-web/api/payment_wo_authorization/actions/get_payment_without_authorization.json',
                        data: formDataExternal,
                        headers: {'Content-Type': 'application/json'}
                    }).success(function (data) {
                        $rootScope.serverError = false;

                        $rootScope.isBackTransition = false;
                        //logonViewState.current.paymentdata = data;

                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        $rootScope.serverError = true;
                        deferred.reject(errorMessage);
                    });
                    return deferred.promise;
                },


                getBeneficiaryBankInfoExternal: function (beneficiaryAccountNumber) {
                    var formDataExternal = {param: "bank_beneficiar_params", value: beneficiaryAccountNumber.toString()}
                    var deferred = $q.defer();
                    $http({
                        method: 'POST',
                        url: '/frontend-web/api/rstyle_internal/actions/get_additional_transfer_params.json',
                        data: formDataExternal,
                        headers: {'Content-Type': 'application/json'}
                    }).success(function (data) {
                        $rootScope.serverError = false;
                        $rootScope.isBackTransition = false;
                        deferred.resolve(data);
                    }).error(function (errorMessage) {
                        $rootScope.serverError = true;
                        deferred.reject(errorMessage);
                    })
                    return deferred.promise;
                },
                acceptPayment: function (paymentCode, accountNumber, bankCode) {
                    var formData = {
                        paymentCode: paymentCode,
                        beneficiaryAccountNumber: accountNumber,
                        beneficiaryBankCode: bankCode
                    };
                    var deferred = $q.defer();
                    $http({
                        method: 'POST',
                        url: '/frontend-web/api/payment_wo_authorization/actions/accept_incoming_payment_wo_authorization.json',
                        data: formData,
                        headers: {'Content-Type': 'application/json'}
                    }).success(function (data) {
                        $rootScope.serverError = false;

                        $rootScope.isBackTransition = false;
                        $location.url("/payment_accept_success");

                        deferred.resolve();
                    }).error(function (errorMessage) {
                        $rootScope.serverError = true;
                        deferred.reject(errorMessage);
                    });
                    return deferred.promise;
                },
                rejectPayment: function (paymentCode) {
                    var formData = {paymentCode: paymentCode};
                    var deferred = $q.defer();
                    $http({
                        method: 'POST',
                        url: '/frontend-web/api/payment_wo_authorization/actions/reject_incoming_payment_wo_authorization.json',
                        data: formData,
                        headers: {'Content-Type': 'application/json'}
                    }).success(function (data) {
                        $rootScope.serverError = false;

                        $rootScope.isBackTransition = false;
                        $location.url("/payment_reject_success");

                        deferred.resolve();
                    }).error(function (errorMessage) {
                        $rootScope.serverError = true;
                        deferred.reject(errorMessage);
                    });
                    return deferred.promise;
                }
            };
        }]);


if (ebProfile(ebProfiles.PROTOTYPE))
    halykAuthenticationModule.factory('authenticationService', ['$q', function ($q) {
        var dummyPromise = function () {
            console.log('dummy ----------------------');
            var defered = $q.defer();
            defer.resolve({});
            return defered.promise;
        };
        return {
            prepareStep1: function () {
                return dummyPromise;
            },
            goToStep2: function () {
                return dummyPromise;
            },
            authenticate: function () {
                return dummyPromise;
            },
            authenticateSMS: function () {
                return dummyPromise;
            },
            prepareStep3ChangePassword: function () {
                return dummyPromise;
            },
            changePassword: function () {
                return dummyPromise;
            },
            prepareStep3NextTokenCode: function () {
                return dummyPromise;
            },
            nextTokenCode: function () {
                return dummyPromise;
            }

        };
    }]);

