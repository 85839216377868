'use strict';

function inputFocus(event) {
    $(event.target).focus();
}

if (!ebProfile(ebProfiles.PROTOTYPE))
    halykAuthenticationModule.controller('passwordController', function ($scope,
                                                                         $rootScope,
                                                                         $state,
                                                                         $window,
                                                                         $timeout,
                                                                         logonViewState,
                                                                         authenticationService,
                                                                         passwordBlockService) {

        if ($state.current.name == "expired" || $state.current.name == "logout" || angular.isUndefined(logonViewState.current.pageConfig)) {
            $state.go("login");
            return;
        }
        var currentLogin = logonViewState.current.login;
        $scope.passwordMaxLength = logonViewState.current.pageConfig.passwordMaxLength;

        var clearInvalidForm = function () {
            if (angular.isDefined($scope.formData)) {
                $scope.formData.j_password = "";
            }
            $scope.authForm.j_password.$setDirty();
        };

        $scope.capsLogStatus = null;

        $scope.onPasswordInputKeyDown = function (event) {
            var BACKSPACE = 27;

            if (event.keyCode == BACKSPACE) {
                $scope.backToPrev();
            }
        };

        $scope.isCredentialsIncorrect = function () {
            return ($scope.authStatus == "CREDENTIALS_INCORRECT");
        };

        $scope.isAccessBlocked = function () {
            return ($scope.authStatus == "CREDENTIALS_CORRECT_BUT_ACCESS_BLOCKED");
        }

        $scope.isAccessTemporarilyBlocked = function () {
            return ($scope.authStatus == "CREDENTIALS_CORRECT_BUT_TEMPORARILY_BLOCKED");
        }

        $scope.isTokenTemporaryBlocked = function () {
            return ($scope.passwordBlockState === "OTP_TEMP");
        }

        $scope.isTokenBlocked = function () {
            return ($scope.passwordBlockState === "OTP_BLOCKED");
        }

        $scope.isPasswordTemporaryBlocked = function () {
            return ($scope.passwordBlockState === "TEMP");
        }

        $scope.isPasswordTimelessBlocked = function () {
            return ($scope.passwordBlockState === "TIMELESS");
        }

        $scope.isLastChanceBeforeTimelessBlock = function () {
            return ($scope.passwordBlockState === "ONE_ATTEMPT_BEFORE_TIMELESS");
        }

        $scope.checkCase = function (event) {
            $scope.capsLogStatus = event.originalEvent.getModifierState('CapsLock');
        };

        $scope.onSubmit = function () {
            $scope.authForm.submitted = true;

            if ($scope.authForm.$valid) {
                $rootScope.isBackTransition = false;
                $scope.passwordBlockState = null;
                return authenticationService.authenticate(currentLogin, $scope.formData.j_password)
                    .then(function (data) {
                        console.log("was success" + data);

                        $scope.authForm.$setPristine();
                        $scope.authStatus = data.status;

                        if ($scope.isCredentialsIncorrect() || $scope.isAccessBlocked() || $scope.isAccessTemporarilyBlocked) {
                            clearInvalidForm();

                            passwordBlockService.getPasswordBlockState(currentLogin)
                                .then(function (passwordBlockState) {
                                    $scope.passwordBlockState = passwordBlockState;

                                    if ($scope.passwordBlockState == 'TIMELESS'
                                        || $scope.passwordBlockState == 'TEMP'
                                        || $scope.passwordBlockState == 'OTP_TEMP'
                                        || $scope.passwordBlockState == 'OTP_BLOCKED') {
                                        setTimeout(function () {
                                            $window.location.reload();
                                        }, 10000);
                                    }
                                });
                        }
                    }, function (errorMessage) {
                        console.log("was errorMessage" + errorMessage);

                        $scope.authForm.$setPristine();
                        clearInvalidForm();
                    });
            } else {
                $scope.authForm.$setDirty();
                $scope.authStatus = "";
                clearInvalidForm();
            }
        };

        $scope.backToPrev = function () {
            $rootScope.isBackTransition = true;
            $state.go("login");
        };

        $scope.reloadPage = $timeout(function () {
            window.location.reload();
        }, 90000);

        $scope.$on('$destroy', function () {
            $timeout.cancel($scope.reloadPage);
        });

        $scope.preventDefault = function (event) {
            event.preventDefault();
        };


    });


if (ebProfile(ebProfiles.PROTOTYPE))
    halykAuthenticationModule.controller('passwordController', function ($scope, $window) {
        if (ebProfile(ebProfiles.PROTOTYPE)) {
            $scope.onSubmit = function () {
                $window.location.href = "../index.html";
            };
        }
    });
