
halykAuthenticationModule.directive('focusOn', function() {
    return {
        scope: {focusOn: "="},
        link:function(scope, elem, attr) {
            scope.$on('focusOn', function(e, name) {
                if(name === scope.focusOn) {
                    elem[0].focus();
                }
            });
        }
    }
        ;
}).factory('focus', function ($rootScope, $timeout) {
    return function(name) {
        $timeout(function (){
            $rootScope.$broadcast('focusOn', name);
        });
    }
});