'use strict';

halykAuthenticationModule.resolvedUsernameConfigData = ['authenticationService', function (authenticationService) {
	return authenticationService.prepareStep1();
}];

halykAuthenticationModule.controller('logoutController', function($scope, $state, $rootScope, logonViewState, localStore) {

	if (!angular.isDefined(localStore.get('redirected')) || localStore.get('redirected') == null
		|| localStore.get('redirected') === "" || !localStore.get('redirected') === true) {
		$state.go("login");
		return;
	}

	$scope.logonAgain = function(){
		$rootScope.isBackTransition = false;
		$state.go("login");
	};

	localStore.clear('redirected');

	logonViewState.reset();
	
    return false;
});