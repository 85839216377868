'use strict';

halykAuthenticationModule.controller('registrationStepThreeController', function ($scope, $state, registrationService, viewStateService, resolvedSetPasswordConfigData) {

    if (angular.isUndefined(viewStateService.getInitialState("registration_step_three"))) {
        $state.go("login");
        return;
    }


    $scope.formData = viewStateService.getInitialState("registration_step_three");

    $scope.registration = false;
    $scope.passwordPolicies = resolvedSetPasswordConfigData;
    $scope.passData = {
        pw2: null,
    };

    function resetPassData() {
        $scope.passData.pw2 = null;
        $scope.formData.password = null;
        $scope.formData.userName = null;
    }

    // function resetClasses() {
    //     $scope.policyClasses = {
    //         length: '',
    //         digit: '',
    //         upper: '',
    //         lower: '',
    //         special: '',
    //         lengthU: '',
    //         contain: ''
    //     };
    // }

    $scope.passwordValid = true;
    $scope.userNameValid = true;

    $scope.policyClasses = {
        length: 'success-password-set',
        digit: 'success-password-set',
        upper: 'success-password-set',
        lower: 'success-password-set',
        special: 'success-password-set',
        lengthU: 'success-password-set',
        unique: 'success-password-set',
        contain: 'success-password-set'
    };


    function watchUserNameValid(userName) {
        $scope.policyClasses.lengthU = 'success-password-set';
        $scope.policyClasses.contain = 'success-password-set';
        $scope.policyClasses.unique = 'success-password-set';

        var valid = true;
        $scope.userNameValid = true;
        var georgianReg = new RegExp('[აბგდევზთიკლმნოპჟრსტუფქღყშჩცძწჭხჯჰ]+');

        var requestParam = {
            login: userName.toLowerCase()
        };


        if (userName.length > 6) {
            registrationService.checkUsernameDuplicates(requestParam).then(function (isDuplicate) {

                if (isDuplicate) {
                    $scope.policyClasses.unique = "wrong-password-set";
                    valid = false;
                    $scope.userNameValid = false;
                }

            });
        }

        if (userName.length < 6 || userName.length > 50) {
            $scope.policyClasses.lengthU = "wrong-password-set";
            valid = false;
        }
        if (georgianReg.test(userName)) {
            $scope.policyClasses.contain = "wrong-password-set";
            valid = false;
        }

        $scope.userNameValid = valid;

        return valid;
    }

    function watchPasswordValid(password) {

        $scope.policyClasses.length = 'success-password-set';
        $scope.policyClasses.digit = 'success-password-set';
        $scope.policyClasses.upper = 'success-password-set';
        $scope.policyClasses.lower = 'success-password-set';
        $scope.policyClasses.special = 'success-password-set';

        var valid = true;
        $scope.passwordValid = true;
        var digitReg = new RegExp('[0-9]+');
        var capitalReg = new RegExp('[A-Z]+');
        var lowerReg = new RegExp('[a-z]+');
        var georgianReg = new RegExp('[აბგდევზთიკლმნოპჟრსტუფქღყშჩცძწჭხჯჰ]+');
        var specChar = $scope.passwordPolicies.specialCharacter;

        if (password.length < $scope.passwordPolicies.minLength) {
            $scope.policyClasses.length = "wrong-password-set";
            valid = false;
        } else if (password.length > $scope.passwordPolicies.maxLength) {
            $scope.policyClasses.length = "wrong-password-set";
            valid = false;
        }
        if ($scope.errorMsg == null) {
            if ($scope.passwordPolicies.digitRequired && !digitReg.test(password)) {
                $scope.policyClasses.digit = "wrong-password-set";
                valid = false;
            }
            if ($scope.passwordPolicies.largeLetterRequired && !capitalReg.test(password)) {
                $scope.policyClasses.upper = "wrong-password-set";
                valid = false;
            }
            if ($scope.passwordPolicies.largeLetterRequired && !lowerReg.test(password)) {
                $scope.policyClasses.lower = "wrong-password-set";
                valid = false;
            }
            if ($scope.passwordPolicies.charRequired) {
                var notExist = true;
                for (var i = 0; i < password.length; i++) {
                    if (specChar.indexOf(password[i]) > -1) {
                        notExist = false;
                        break;
                    }
                }
                if (notExist) {
                    $scope.policyClasses.special = "wrong-password-set";
                    valid = false;
                }
            }

        }

        $scope.passwordValid = valid;

        return valid;
    }

    $scope.preventDefault = function (event) {
        event.preventDefault();
    };

    $scope.$watch('passData.pw2', function (newVal, oldVal) {
        if (newVal != oldVal) {
            watchPasswordValid(newVal)
        }
    });


    $scope.$watch('formData.userName', function (newVal, oldVal) {
        if (newVal != oldVal) {
            watchUserNameValid(newVal)
        }
    });


    function passwordValid(password, userName) {
        $scope.policyClasses = {
            length: 'success-password-set',
            digit: 'success-password-set',
            upper: 'success-password-set',
            lower: 'success-password-set',
            special: 'success-password-set',
            lengthU: 'success-password-set',
            contain: 'success-password-set'
        };
        var valid = true;
        $scope.passwordValid = true;
        var digitReg = new RegExp('[0-9]+');
        var capitalReg = new RegExp('[A-Z]+');
        var lowerReg = new RegExp('[a-z]+');
        var georgianReg = new RegExp('[აბგდევზთიკლმნოპჟრსტუფქღყშჩცძწჭხჯჰ]+');
        var specChar = $scope.passwordPolicies.specialCharacter;

        if (userName.length < 6 || userName.length > 50) {
            $scope.policyClasses.lengthU = "wrong-password-set";
            valid = false;
        }
        if (georgianReg.test(userName)) {
            $scope.policyClasses.contain = "wrong-password-set";
            valid = false;
        }
        if (password.length < $scope.passwordPolicies.minLength) {
            $scope.policyClasses.length = "wrong-password-set";
            valid = false;
        } else if (password.length > $scope.passwordPolicies.maxLength) {
            $scope.policyClasses.length = "wrong-password-set";
            valid = false;
        }
        if ($scope.errorMsg == null) {
            if ($scope.passwordPolicies.digitRequired && !digitReg.test(password)) {
                $scope.policyClasses.digit = "wrong-password-set";
                valid = false;
            }
            if ($scope.passwordPolicies.largeLetterRequired && !capitalReg.test(password)) {
                $scope.policyClasses.upper = "wrong-password-set";
                valid = false;
            }
            if ($scope.passwordPolicies.largeLetterRequired && !lowerReg.test(password)) {
                $scope.policyClasses.lower = "wrong-password-set";
                valid = false;
            }
            if ($scope.passwordPolicies.charRequired) {
                var notExist = true;
                for (var i = 0; i < password.length; i++) {
                    if (specChar.indexOf(password[i]) > -1) {
                        notExist = false;
                        break;
                    }
                }
                if (notExist) {
                    $scope.policyClasses.special = "wrong-password-set";
                    valid = false;
                }
            }

        }
        $scope.passwordValid = valid;
        return valid;
    }

    $scope.onSubmit = function (form) {
        //resetClasses();
        $scope.registrationForm.submitted = true;
        if ($scope.registrationForm.$valid) {
            if (!passwordValid($scope.formData.password, $scope.formData.userName)) {
                return;
            }

            $scope.registrationParams = {
                password: $scope.formData.password,
                userName: $scope.formData.userName,
                personalId: $scope.formData.personalId,
                phoneNumber: $scope.formData.phoneNumber,
                deviceId: "INTBANK",
                language: "ge"
            }

            registrationService.registerUser($scope.registrationParams).then(function (response) {
                if (response.result) {
                    $state.go("registrationSuccess");
                    // var params = {
                    //     language: "ge",
                    //     phoneNumber: $scope.formData.phoneNumber,
                    //     personalId: $scope.formData.personalId
                    // };

                    //registrationService.sendApplication(params);
                } else {
                    $state.go("registrationUnsuccess");
                }
            }, function (errorMessage) {
                //resetClasses();
                resetPassData();
            });

        } else {
            $scope.registrationForm.$setDirty();
            $scope.fieldsRequired = true;
        }
    };


    $scope.backToPrev = function () {
        viewStateService.resetAllStates();
        $state.go("registration_step_one");
    };

});

