'use strict';

halykAuthenticationModule.controller('registrationStepTwoController', function ($scope, $state, registrationService, viewStateService) {

    if (angular.isUndefined(viewStateService.getInitialState("registration_step_two"))) {
        $state.go("login");
        return;
    }

    $scope.formData = viewStateService.getInitialState("registration_step_two");
    $scope.smsDataMail = {};
    $scope.smsDataPhone = {};

    function ressData() {
        $scope.formData.cardNumberTail = null;
        $scope.formData.expirationMonth = null;
        $scope.formData.expirationYear = null;
        $scope.formData.cardNumberHead = null;
        $scope.smsDataMail.otpMail = null;
        $scope.smsDataPhone.otpPhone = null;
    }


    $scope.resendCodeMail = function () {
        $scope.params = {
            codeOf: "mail"
        };

        registrationService.resendSmsCode($scope.params)
    };
    $scope.resendCodePhone = function () {
        $scope.params = {
            codeOf: "phone"
        };
        registrationService.resendSmsCode($scope.params)
    };

    $scope.onSubmit = function (form) {
        $scope.registrationForm.submitted = true;
        if ($scope.registrationForm.$valid && isAllFormFieldsIsNotNull()) {

            registrationService.validateSmsPhone($scope.smsDataPhone).then(function (response) {
                if (response.result) {

                    if ($scope.formData.withMail) {
                        registrationService.validateSmsMail($scope.smsDataMail).then(function (response) {
                            if (response.result) {
                                $scope.dataForNextStep = {
                                    personalId: $scope.formData.personalId,
                                    phoneNumber: $scope.formData.phoneNumber
                                };

                                var params = {
                                    language: "ge",
                                    phoneNumber: $scope.formData.phoneNumber,
                                    personalId: $scope.formData.personalId,
                                    deviceId: "INTBANK"
                                };


                                if ($scope.formData.isActivation) {
                                    registrationService.selfActivateUser(params).then(function (response) {
                                        viewStateService.setInitialState("registrationSuccess", {resultCode: "db.auth.selfRegistration.success.info2"});
                                        $state.go("registrationSuccess");
                                    });

                                } else {
                                    viewStateService.setInitialState("registration_step_three", $scope.dataForNextStep);
                                    $state.go("registration_step_three");
                                }
                            } else {
                                $scope.smsDataMail.otpMail = null;
                                $scope.smsDataPhone.otpPhone = null;
                            }
                        });
                    } else {
                        registrationService.validateCardInfo($scope.formData).then(function (response) {
                            if (response.success) {
                                $scope.dataForNextStep = {
                                    personalId: $scope.formData.personalId,
                                    phoneNumber: $scope.formData.phoneNumber
                                };
                                viewStateService.setInitialState("registration_step_three", $scope.dataForNextStep);
                                $state.go("registration_step_three");
                            } else {
                                viewStateService.setInitialState("registrationUnsuccess", {resultCode: response.resultCode});
                                $state.go("registrationUnsuccess");
                            }
                        }, function (errorMessage) {
                            console.log("was errorMessage" + errorMessage);
                            viewStateService.setInitialState("registrationUnsuccess", {resultCode: errorMessage});
                            $state.go("registrationUnsuccess");
                        });
                    }
                } else {
                    ressData();
                }
            });
        } else {
            $scope.registrationForm.$setDirty();
            $scope.fieldsRequired = true;
        }
    };


    var isAllFormFieldsIsNotNull = function () {
        if ($scope.formData.personalId == null || $scope.formData.phoneNumber == null) {
            return false;
        } else {
            return true
        }
    };


    $scope.backToPrev = function () {
        viewStateService.resetAllStates();
        $state.go("registration_step_one");
    };

});

