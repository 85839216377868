'use strict';
halykAuthenticationModule.controller('passwordForgotController', function ($scope, $rootScope, $state, passwordForgotViewState, resetPasswordService, loginResolvedData) {
    console.log("passwordForgotViewState", passwordForgotViewState);

    if (angular.isUndefined(passwordForgotViewState.current.formData)) {
        $scope.formData = {
            checkUserOnly: true,
            personalId: null,
            email: null,
            mobilePhone: null,
            login: null
        };
    } else {
        $scope.formData = passwordForgotViewState.current.formData;
    }

    $scope.personalIdMaxLength = 11;
    $scope.phoneNumberMaxLength = 12;
    $scope.emailMaxLength = 250;
    $scope.isEmailCorrect = true;
    $scope.userNotFound = false;

    $scope.usernameMaxLength = loginResolvedData.usernameMaxLength;

    $scope.onSubmit = function () {
        passwordForgotViewState.reset();

        $scope.forgotPasswordForm.submitted = true;
        $scope.isEmailCorrect = validateEmail();

        if ($scope.forgotPasswordForm.$valid && $scope.isEmailCorrect) {
            $rootScope.isBackTransition = false;

            return resetPasswordService.reset($scope.formData).then(function (result) {
                console.log("was success" + result);

                if (result) {
                    passwordForgotViewState.current.formData = $scope.formData;
                    $state.go("passwordForgotConfirm");
                } else {
                    $scope.userNotFound = !result;
                    $scope.forgotPasswordForm.$setUntouched();
                }
            }).catch(function (errorMessage) {
                console.error("was errorMessage", errorMessage);
                $scope.userNotFound = true;
                $scope.forgotPasswordForm.$setUntouched();
            });
        } else {
            $scope.forgotPasswordForm.$setDirty();
            angular.forEach($scope.forgotPasswordForm, function (input, key) {
                if (typeof input === 'object' && input.hasOwnProperty('$modelValue')) {
                    input.$setDirty();
                }
            });
        }
    };

    $scope.backToPrev = function () {
        $rootScope.isBackTransition = true;
        $state.go("login");
    };

    $scope.preventDefault = function (event) {
        event.preventDefault();
    };

    $scope.$watch('formData.email', function (newVal) {
        if (newVal) {
            $scope.isEmailCorrect = true;
        }
    });

    var validateEmail = function () {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test($scope.formData.email);
    };
});
