'use strict';


halykAuthenticationModule.controller('passwordTokenController', function($scope, $rootScope, $state, logonViewState, authenticationService, passwordBlockService) {

	if($state.current.name == "expired" || $state.current.name == "logout" || angular.isUndefined(logonViewState.current.pageConfig)){
		$state.go("login");
		return;
	}

	var currentLogin = logonViewState.current.login;
	$scope.tokenCodeAndPasswordMaxLength = logonViewState.current.pageConfig.tokenCodeAndPasswordMaxLength;


    var clearInvalidForm = function() {
        if (angular.isDefined($scope.formData)) {
            $scope.formData.j_password = "";
        }
        $scope.authForm.j_password.$setDirty();
    };

    $scope.onPasswordInputKeyDown = function(event) {
        var BACKSPACE = 27;

        if(event.keyCode == BACKSPACE) {
            $scope.backToPrev();
        }
    };

    $scope.isCredentialsIncorrect = function() {
        return ($scope.authStatus == "CREDENTIALS_INCORRECT");
    };

    $scope.isAccessBlocked = function(){
        return ($scope.authStatus == "CREDENTIALS_CORRECT_BUT_ACCESS_BLOCKED");
    }

    $scope.onSubmit = function() {
        $scope.authForm.submitted = true;

    	if($scope.authForm.$valid) {
	    	$rootScope.isBackTransition = false;
	    	
	    	return authenticationService.authenticate(currentLogin, $scope.formData.j_password)
    		.then(function(data){
    			console.log("was success" + data);

    			$scope.authForm.$setPristine();
    			$scope.authStatus = data.status;

                if($scope.isCredentialsIncorrect() || $scope.isAccessBlocked()) {
                    clearInvalidForm();
                    passwordBlockService.getPasswordBlockState(currentLogin)
                        .then(function (passwordBlockState) {
                            $scope.passwordBlockState = passwordBlockState;

                            if ($scope.passwordBlockState === 'OTP_TEMP' || $scope.passwordBlockState === 'OTP_BLOCKED') {
                                setTimeout(function () {
                                    $window.location.reload();
                                }, 10000);
                            }
                        });

                }
            },function(errorMessage){ 
            	console.log("was errorMessage" + errorMessage); 

            	$scope.authForm.$setPristine();
                clearInvalidForm();
            });
    	} else {
    		$scope.authForm.$setDirty();
            $scope.authStatus = "";
            clearInvalidForm();
    	}
    };

    $scope.backToPrev = function() {
        $rootScope.isBackTransition = true;
        $state.go("login");
    };
    
    $scope.preventDefault = function(event) {
        event.preventDefault();
    };

    $scope.isTokenTemporaryBlocked = function () {
        return ($scope.passwordBlockState === "OTP_TEMP");
    }

    $scope.isTokenBlocked = function () {
        return ($scope.passwordBlockState === "OTP_BLOCKED");
    }

});
