'use strict';

halykAuthenticationModule.controller('passwordTokenNextController', function($scope, $state, $rootScope, logonViewState, resolvedNextTokenCodeConfigData, authenticationService) {
	
	if($state.current.name == "expired" || $state.current.name == "logout"){
		$state.go("login");
		return;
	}
	if(angular.isDefined(logonViewState.current.authResult)) {
		$scope.authStatus = logonViewState.current.authResult.status;
	}
	
	$scope.tokenCodeMaxLength = resolvedNextTokenCodeConfigData.tokenCodeMaxLength;
	
	$scope.code = '';
	
	var clearInvalidForm = function() {
		$scope.code = "";
		$scope.nextTokenForm.code.$setDirty();
	};

    var logout = function() {
        $rootScope.isBackTransition = false;
        $state.go("login");
        logonViewState.reset();
    };

	$scope.isNextTokenCodeIncorrect = function() {
		return ($scope.nextTokenCodeStatus == "NEXT_TOKEN_CODE_INCORRECT");
	};

    $scope.onSubmit = function() {
        $scope.nextTokenForm.submitted = true;
        
    	if($scope.nextTokenForm.$valid) {
    		$rootScope.isBackTransition = false;
    		
    		$scope.authStatus = null;
    		
        	return authenticationService.nextTokenCode($scope.code)
        		.then(function(data) {
        			console.log("was success" + data);

        			$scope.nextTokenForm.$setPristine();
        			$scope.nextTokenCodeStatus = data.status;

        			if ($scope.isNextTokenCodeIncorrect()) {
        				clearInvalidForm();
                        logout();
        			}
                },function(errorMessage) { 
                	console.log("was errorMessage" + errorMessage); 

                	clearInvalidForm();
                    logout();
                });
    	} else {
    		$scope.nextTokenForm.$setDirty();
    		$scope.nextTokenCodeStatus = "";
			clearInvalidForm();
    	}
    };
	   
	$scope.backToPrev = function(){
		$rootScope.isBackTransition = true;
		$state.go("login");
	};

    $scope.preventDefault = function(event) {
        event.preventDefault();
    };	
});

