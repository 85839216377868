halykAuthenticationModule.service('passwordBlockService', function($rootScope, httpService, gate) {

    'use strict';
    
    return {
        getPasswordBlockState: function (currentLogin) {
            var sendApplicationUrl = "/api/bo_access_method/actions/password_block_status.json";
            return httpService.post(sendApplicationUrl, {username: currentLogin});
        }
    }
});