'use strict';

halykAuthenticationModule.controller('paymentController', function ($scope, logonViewState, $state, authenticationService, $stateParams) {

    $scope.error = false;
    $scope.actionError = false;
    $scope.beneficiaryAccountNumberExternal = "";
    $scope.beneficiaryBankCodeExternal = "";
    $scope.incorrectIban = null;
    $scope.isClicked = false;
    if ($stateParams.paymentCode) {
        $scope.paymentPromise = authenticationService.getIncomingPayment(
            $stateParams.paymentCode).then(function (data) {
            $scope.payment = data;
            if (data.title === 'SERVER.ERROR') {
                $scope.error = true;
                $scope.errorMessageCode = data.description;
            }
        }, function (errorMessage) {
            $scope.error = true;
        });
    }

    $scope.getBenefBankInfo = function (accountNumber) {
        authenticationService.getBeneficiaryBankInfoExternal(accountNumber).then(function (result) {
            if (result.success === true) {
                $scope.beneficiaryBankCodeExternal = result.data.beneficiaryBankCode;
                $scope.beneficiaryAccountNumberExternal = result.data.beneficiaryAccountNumber;
                $scope.incorrectIban = false;
            } else {
                $scope.incorrectIban = true;
            }
        });
    }

    $scope.accept = function () {
        if ($scope.isClicked) return;

        $scope.actionError = false;
        $scope.isClicked = true;
        authenticationService.acceptPayment($stateParams.paymentCode, $scope.beneficiaryAccountNumberExternal, $scope.beneficiaryBankCodeExternal).then(
            function () {
                $scope.isClicked = false;
            }, function (error) {
                $scope.isClicked = false;
                $scope.actionError = true;
            });
    }

    $scope.reject = function () {
        $scope.actionError = false;
        authenticationService.rejectPayment($stateParams.paymentCode).then(function () {

        }, function (error) {
            $scope.actionError = true;
        });
    }

    $scope.isValidAccountNumber = function (accNumber) {
        var accNum = accNumber[0];
        return (accNum == undefined || accNum.length < 22) || ($scope.incorrectIban == true || $scope.incorrectIban == null);
    }
});
