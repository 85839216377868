'use strict';

function inputFocus(event) {
    $(event.target).focus();
}

halykAuthenticationModule.controller('loginController', function ($scope, $rootScope, $cookies, $timeout, $http, logonViewState, loginResolvedData, authenticationService, $state, $analytics, passwordForgotViewState) {

    $scope.system = {};

    $scope.remeberUserName = true;


    if ($state.current.name == "expired" || $state.current.name == "logout") {
        $state.go("login");
        return;
    }
    if (angular.isDefined(logonViewState.current.authResult)) {
        $scope.authStatus = logonViewState.current.authResult.status;
    }

    $scope.preventDefault = function (event) {
        event.preventDefault();
    };

    $scope.usernameMaxLength = loginResolvedData.usernameMaxLength;

    $scope.onSubmit = function () {
        logonViewState.reset();
        $scope.authStatus = null;
        $scope.authForm.submitted = true;

        if ($scope.authForm.$valid && $scope.autofocusDirty) {
            $rootScope.isBackTransition = false;
            $analytics.setUsername($scope.formData.j_username.toLowerCase() + '_' + $cookies.get('JSESSIONID'));
            authenticationService.goToStep2($scope.formData.j_username.toLowerCase())
                .then(function (data) {
                    console.log("was success" + data);
                    return;
                }, function (errorMessage) {
                    console.log("was errorMessage" + errorMessage);
                    $scope.formData.j_username = "";
                    return;
                });

        } else {
            $scope.authForm.$setDirty();
            $scope.autofocusDirty = true;
            //execute validation on field
            $scope.authForm.j_username.toLowerCase().$setViewValue($scope.authForm.j_username.toLowerCase().$viewValue);
        }
        return false;
    };

    $scope.autofocusDirty = false;
    $scope.watchRelease = $scope.$watch('formData.j_username', function (newVal, oldVal) {
        if (newVal != null && newVal.length > 0) {
            $scope.autofocusDirty = true;
            $scope.watchRelease();
        }
    });

    $scope.goToPasswordForgot = function () {
        if ($scope.formData && $scope.formData.j_username.toLowerCase()) {
            passwordForgotViewState.current.formData = {
                checkUserOnly: true,
                personalId: null,
                email: null,
                mobilePhone: null,
                login: $scope.formData.j_username.toLowerCase()
            };
        }
        $state.go("passwordForgot");
    };

    $scope.reloadPage = $timeout(function () {
        window.location.reload();
    }, 60000);

    $scope.$on('$destroy', function () {
        $timeout.cancel($scope.reloadPage);
    });

    if (ebProfile(ebProfiles.PROTOTYPE)) {
        $scope.onSubmit = function () {
            $state.go("password");
        }
    }

});

