'use strict';

halykAuthenticationModule.directive('cbBanner', function( pathService, cbBrandingService) {
	return {
        restrict: 'EA',
        replace: true,
        scope: true,
        templateUrl: pathService.generateTemplatePath("halyk-authentication") + '/directives/cbBanner/cbBanner.html',
        controller: function ($scope) {
            $scope.bannerUrl = cbBrandingService.getBannerUrl();
        }
    };
});