'use strict';

halykAuthenticationModule.controller('successController', function($scope, $rootScope, $state, logonViewState, authenticationService) {
    $scope.logonAgain = function () {
        $rootScope.isBackTransition = false;
        $state.go("login");
    };

    logonViewState.reset();

    return false;
});
