'use strict';

halykAuthenticationModule.controller('registrationSuccessController', function($scope, $state, translate,viewStateService) {


	if (angular.isUndefined(viewStateService.getInitialState("registrationUnsuccess"))) {

		$scope.message = ""

	} else {
		$scope.result = viewStateService.getInitialState("registrationSuccess");

		$scope.message = translate.property($scope.result.resultCode)
	}


	$scope.onSubmit = function() {
		$state.go("login");
	}
	   
});

